import React from 'react';

import styles from './RiseInterstitialTemplate.module.css';
import {RiseInterstitialProps} from './RiseInterstitialBase';
import RiseSurveyInterstitialControls from '../RiseSurveyControls/RiseSurveyInterstitialControls';

export type RiseInterstitialTemplateProps = {
  preHeaderText?: string | React.ReactNode;
  headerText?: string | React.ReactNode;
  bodyText?: string | React.ReactNode;
  heroImage?: React.ReactNode;
  secondaryImage?: React.ReactNode;
  continueButtonTitle?: string;
  onClick?: () => void;
} & RiseInterstitialProps;

const RiseInterstitialTemplate: React.FC<RiseInterstitialTemplateProps> = ({
  preHeaderText,
  headerText,
  bodyText,
  heroImage,
  secondaryImage,
  continueButtonTitle,
  onClick,
}) => {
  return (
    <div className={styles.container}>
      {preHeaderText ? (
        <div className={styles.preHeaderText}>
          {typeof preHeaderText === 'string' ? (
            <div dangerouslySetInnerHTML={{__html: preHeaderText.replaceAll(/[\s\u200B]+/g, ' ')}} />
          ) : (
            preHeaderText
          )}
        </div>
      ) : null}
      {headerText ? (
        <div className={styles.headerText}>
          {typeof headerText === 'string' ? (
            <div dangerouslySetInnerHTML={{__html: headerText.replaceAll(/[\s\u200B]+/g, ' ')}} />
          ) : (
            headerText
          )}
        </div>
      ) : null}
      {heroImage ? <div className={styles.heroImage}>{heroImage}</div> : null}
      {bodyText ? (
        <div className={styles.bodyText}>
          {typeof bodyText === 'string' ? (
            <div dangerouslySetInnerHTML={{__html: bodyText.replaceAll(/[\s\u200B]+/g, ' ')}} />
          ) : (
            bodyText
          )}
        </div>
      ) : null}
      {secondaryImage ? <div className={styles.secondaryImage}>{secondaryImage}</div> : null}
      <RiseSurveyInterstitialControls continueButtonTitle={continueButtonTitle} onClick={onClick} />
    </div>
  );
};
export default RiseInterstitialTemplate;
