import React from 'react';
import ButtonSolid from 'components/Buttons/ButtonSolid';
import Analytics from 'utils/Analytics';
import {surveyInstance} from '../../RiseSurvey';
import {SurveyData} from '../../utils/surveyData';

type ContinueButtonProps = {
  onClick?: (() => void) | (() => Promise<void>);
  title?: string;
  variant?: 'default' | 'inverted';
  disabled?: boolean;
  loading?: boolean;
};

export const ContinueButton: React.FC<ContinueButtonProps> = ({
  title,
  onClick,
  variant = 'default',
  disabled,
  loading,
}) => {
  return (
    <ButtonSolid
      title={title ?? 'Continue'}
      onClick={async () => {
        try {
          // This tap dance addresses that onClick is only sometimes a promise
          const result = onClick?.();
          if (result instanceof Promise) {
            await result;
          }
          const page = surveyInstance.currentPage;
          Analytics.track('Continue clicked', {
            survey_name: surveyInstance.title,
            question_title: page.questions[0].title,
            question_name: page.questions[0].name,
            question_value: page.questions[0]?.value,
          });
          if (surveyInstance) {
            SurveyData.save(surveyInstance);
          }
          if (surveyInstance?.isLastPage) {
            surveyInstance?.completeLastPage();
          } else {
            surveyInstance?.nextPage();
          }
        } catch (error) {
          console.error('Error clicking continue button', error);
          Analytics.track('Continue clicked error', {
            error,
          });
        }
      }}
      variant={variant}
      disabled={disabled}
      loading={loading}
    />
  );
};
