import React from 'react';
import styles from './PriceToday.module.css';
import giftIcon from './assets/gift.svg';
type Props = {
  title: string;
  price: string;
  currencySymbol: string;
};

export default function PriceToday({title, price, currencySymbol}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Price Today</div>
      <div className={styles.priceContainer}>
        <div className={styles.priceIcon}>
          <img src={giftIcon} alt="gift" />
        </div>
        <div className={styles.priceTitle}>{title}</div>
        <div className={styles.price}>
          <div className={styles.currency}>{currencySymbol}</div>
          {price}
        </div>
      </div>
    </div>
  );
}
