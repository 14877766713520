import {PayPalButtons} from '@paypal/react-paypal-js';
import {CardElement, Elements, ElementsConsumer, PaymentRequestButtonElement} from '@stripe/react-stripe-js';
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeElements,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {createCheckoutDetails, payWithCreditCard, payWithPlatformPay} from 'externalAPIs/hypnos';
import {createPaymentRequest, createStripeInstance} from 'externalAPIs/stripe';
import {useCallback, useEffect, useRef, useState} from 'react';
import ReactPixel from 'react-facebook-pixel';
import Analytics from 'utils/Analytics';
import {PlanInfo} from 'utils/stripePlans';
import styles from './PaymentElement.module.css';
import checkout_logos from './assets/checkout-logos.webp';
import mastercard_logo from './assets/mastercard_id_check.png';
import purchase_lock from './assets/purchase_lock.svg';
import ssl_logo from './assets/secure_ssl_encryption.png';
import stripe_logo from './assets/stripe.png';
import visa_logo from './assets/visa_secure.png';
import common from 'common.module.css';
import {LoadingSpinner} from '../LoadingSpinner/LoadingSpinner';

export const PaymentElement = ({
  onSuccess,
  planToPurchase,
}: {
  onSuccess: (subscription: string) => void;
  planToPurchase: PlanInfo;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [clientSecret, setClientSecret] = useState<string | undefined>('');
  const [stripeInstance, setStripeInstance] = useState<Stripe | null>(null);
  const [options, setOptions] = useState<StripeElementsOptions | null>(null);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined);

  const loadingStripeRef = useRef<boolean>(false);

  const handlePurchase = useCallback(
    async (purchaseMethod: () => Promise<{subscription: string | null; error: string | null}>) => {
      setError('');
      setLoading(true);
      const {subscription, error} = await purchaseMethod();
      if (subscription) {
        onSuccess(subscription);
      } else {
        setError(error ?? 'Could not complete checkout. Please try again or use an alternate method.');
      }
      setLoading(false);
    },
    [onSuccess, setError, setLoading],
  );

  const purchaseWithCreditCard = async (elements: StripeElements | null) => {
    await handlePurchase(async () => await payWithCreditCard(planToPurchase, elements, stripeInstance, clientSecret));
  };

  // Apple Pay Handler: If user can use Apple/Google Pay, set up the payment request handler
  useEffect(() => {
    const handler = async (event: PaymentRequestPaymentMethodEvent) => {
      await handlePurchase(async () => {
        const {subscription, error} = await payWithPlatformPay(planToPurchase, event);
        if (subscription) {
          event.complete('success');
        } else {
          event.complete('fail');
        }
        return {subscription, error};
      });
    };

    // Listen for event to handle Apple/Google Pay
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', handler);
    }

    return () => {
      // Unsubscribe from the event when the useEffect is re-rendered
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', handler);
      }
    };
  }, [planToPurchase, paymentRequest, handlePurchase]);

  useEffect(() => {
    const setupStripe = async () => {
      if (!stripeInstance && !loadingStripeRef.current) {
        loadingStripeRef.current = true;
        const {client_secret} = await createCheckoutDetails();
        setClientSecret(client_secret);
        setOptions({
          clientSecret: client_secret,
          appearance: {
            variables: {
              colorPrimary: '#0570de',
              colorBackground: '#30313d',
              colorText: 'white',
              colorDanger: '#df1b41',
              borderRadius: '4px',
              colorPrimaryText: 'white',
              fontSizeSm: '1rem',
            },
          },
        });
        const stripe = await createStripeInstance();
        if (stripe) {
          setStripeInstance(stripe);
        }
        loadingStripeRef.current = false;
      }
    };

    setupStripe();
  }, [stripeInstance, loadingStripeRef]);

  useEffect(() => {
    if (stripeInstance) {
      if (paymentRequest) {
        paymentRequest.update({
          total: {
            label: planToPurchase.frequency,
            amount: planToPurchase.purchasePrice,
          },
        });
      } else {
        createPaymentRequest(stripeInstance, planToPurchase).then(paymentRequest => {
          // likely reason to be undefined is that Apple Pay or Google Pay are not supported
          setPaymentRequest(paymentRequest);
        });
      }
    }
  }, [planToPurchase, paymentRequest, setPaymentRequest, stripeInstance]);

  if (!stripeInstance || !options) {
    return null;
  }

  const plan_id = planToPurchase.payPalPlanId;

  return (
    <Elements stripe={stripeInstance} options={options}>
      <ElementsConsumer>
        {({elements}) => (
          <section>
            {loading ? <LoadingSpinner /> : null}
            <div className={common.heading1}>Payment method</div>

            {error ? <p style={{color: 'red'}}>{error}</p> : null}
            <div className={styles.checkoutContainer}>
              {stripeInstance && paymentRequest ? (
                <div className="mt-4">
                  <PaymentRequestButtonElement
                    options={{
                      style: {
                        paymentRequestButton: {
                          theme: 'dark',
                          height: '55px',
                        },
                      },
                      paymentRequest,
                    }}
                    onClick={() => {
                      Analytics.track('Platform Pay Clicked');
                    }}
                  />
                </div>
              ) : null}
              <div className={styles.paypal}>
                <PayPalButtons
                  style={{
                    layout: 'horizontal',
                    color: 'gold',
                    tagline: false,
                    height: 55,
                  }}
                  forceReRender={[plan_id]}
                  onApprove={async data => {
                    if (data.subscriptionID) {
                      onSuccess(data.subscriptionID);
                      Analytics.track('PayPal Checkout Success', {
                        subscription: data.subscriptionID,
                        ...planToPurchase,
                      });
                      Analytics.track('PaymentAccepted');
                      ReactPixel.track('Purchase', {
                        currency: planToPurchase.currency.toUpperCase(),
                        value: planToPurchase.introPrice,
                      });
                      try {
                        dataLayer.push({event: 'conversion', value: planToPurchase.introPrice, currency: planToPurchase.currency.toUpperCase()});
                      } catch (e) {
                        // Silently handle missing dataLayer
                      }
                    } else {
                      setError('Could not complete PayPal Checkout. Please try again or use an alternate method.');
                      Analytics.track('PayPal Checkout Error', {message: 'Did not return subscription id', ...data});
                    }
                  }}
                  createSubscription={async (data, actions) => {
                    Analytics.track('PayPal Checkout Created', data);
                    return actions.subscription.create({
                      plan_id: planToPurchase.payPalPlanId,
                    });
                  }}
                  onInit={() => {
                    Analytics.track('PayPal Buttons Shown');
                  }}
                  onClick={data => {
                    setError('');
                    Analytics.track('PayPal Button Clicked', data);
                  }}
                  onCancel={data => {
                    Analytics.track('PayPal Checkout Cancelled', data);
                  }}
                  onError={err => {
                    setError('Could not complete PayPal Checkout. Please try again or use an alternate method.');
                    Analytics.track('PayPal Checkout Error', err);
                  }}
                />
              </div>
              <div className={styles.creditCard}>
                <div className={styles.cardOptions}>
                  <div className={styles.creditCardTitle}>Credit card</div>
                  <div style={{height: '1.5rem'}}>
                    <img width="150" className={styles.paymentLogo} src={checkout_logos} alt="Card logos" />
                  </div>
                </div>
                <p className={styles.cardDetails}>Card details</p>
                <form
                  onSubmit={async event => {
                    event.preventDefault();
                    await purchaseWithCreditCard(elements);
                  }}
                >
                  <div
                    style={{
                      border: '1px solid #D3D3D3',
                      borderRadius: '2px',
                      padding: '1.5px',
                      paddingLeft: '12px',
                      marginBottom: '1rem',
                      paddingTop: '12px',
                      paddingBottom: '12px',
                    }}
                  >
                    <CardElement
                      options={{
                        style: {
                          base: {
                            backgroundColor: 'white',
                          },
                        },
                      }}
                      onFocus={() => {
                        Analytics.track('Credit Card Form Focused');
                      }}
                    />
                  </div>
                  <button id="submit" className={styles.button}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <div className={styles.confirmPurchaseButton}>
                        <img src={purchase_lock} alt=" Purchase Lock" />
                        <span>Confirm Purchase</span>
                      </div>
                    </div>
                  </button>
                </form>
                <div className={styles.encryption}>
                  <div className={styles.encryptionDetails}>
                    Your payment information is secure with SSL/TLS encryption
                  </div>
                  <div className={styles.paymentSecurityImageContainer}>
                    <div className={styles.paymentSecurityImage}>
                      <img className={styles.paymentLogo} src={ssl_logo} alt="Secure SSL Encryption" />
                    </div>
                    <div className={styles.paymentSecurityImage}>
                      <img className={styles.paymentLogo} src={visa_logo} alt="Visa Secure" />
                    </div>
                    <div className={styles.paymentSecurityImage}>
                      <img className={styles.paymentLogo} src={stripe_logo} alt="Powered By Stripe" />
                    </div>
                    <div className={styles.paymentSecurityImage}>
                      <img className={styles.paymentLogo} src={mastercard_logo} alt="Mastercard ID Check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </ElementsConsumer>
    </Elements>
  );
};
