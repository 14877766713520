import common from 'common.module.css';
import {getUserCountry} from 'utils/locale';
import {config7dMonthly20, config7dQuarterly30, PlanInfo} from 'utils/stripePlans';
import {PurpleButton} from '../CheckoutButtons/CheckoutButtons';
import {PlanOption} from './components/PlanOption';
import styles from './PlanSelection.module.css';

export const PlanSelection = ({
  selectedPlan,
  onPriceSelection,
  onButtonClick,
  trailEndDate,
}: {
  selectedPlan: PlanInfo;
  onPriceSelection: (plan: PlanInfo) => void;
  onButtonClick: () => void;
  trailEndDate: string;
}) => {
  const {countryCode: locale} = getUserCountry();
  const winbackMonthlyPlan = config7dMonthly20[locale];
  const winbackQuarterlyPlan = config7dQuarterly30[locale];

  const quarterlySave = Math.round(winbackMonthlyPlan.price * 3 - winbackQuarterlyPlan.price);

  const monthBadgeText = winbackMonthlyPlan.percentOff
    ? `SAVE ${Math.round(winbackMonthlyPlan.percentOff * 100)}%`
    : null;
  const quarterBadgeText = winbackQuarterlyPlan.percentOff
    ? `SAVE ${Math.round(winbackQuarterlyPlan.percentOff * 100)}%`
    : null;

  const renewalRateText = selectedPlan.frequency === 'Monthly' ? 'month' : '3 months';
  return (
    <section className={styles.container}>
      <div className={styles.title}>Price after trial</div>

      <div className={styles.planSelectionContainer}>
        {/* Quarterly Plan */}
        <PlanOption
          index={1}
          selected={selectedPlan.id === winbackQuarterlyPlan.id}
          offerDetails={
            <div>
              <strong>
                Save {winbackQuarterlyPlan.label}
                {quarterlySave}
              </strong>{' '}
              compared to the monthly plan
            </div>
          }
          currencySymbol={winbackQuarterlyPlan.label}
          pricePerDay={winbackQuarterlyPlan.perDay}
          planPrice={winbackQuarterlyPlan.comparePrice}
          planIntroPrice={winbackQuarterlyPlan.price}
          badgeText={quarterBadgeText}
          planTitle="Quarterly"
          onClick={() => onPriceSelection(winbackQuarterlyPlan)}
        />
        {/* Monthly Plan */}
        <PlanOption
          index={2}
          selected={selectedPlan.id === winbackMonthlyPlan.id}
          currencySymbol={winbackMonthlyPlan.label}
          pricePerDay={winbackMonthlyPlan.perDay}
          planPrice={winbackMonthlyPlan.comparePrice}
          planIntroPrice={winbackMonthlyPlan.price}
          badgeText={monthBadgeText}
          planTitle="Monthly"
          onClick={() => onPriceSelection(winbackMonthlyPlan)}
        />
        <div className={[common.left, common.colorGray2, common.smallText].join(' ')}>
          Cancel anytime. {selectedPlan.label}
          {selectedPlan.price} billed on {trailEndDate} with automatic renewal every {renewalRateText} unless canceled.
        </div>
        <div className={styles.planSelectionButton}>
          <PurpleButton title="Get my plan" onClick={onButtonClick} />
        </div>
        <div className={[common.center, common.colorGray2, common.smallText].join(' ')}>
          Guaranteed safe checkout and
          <br />
          100% money-back guarantee
        </div>
      </div>
    </section>
  );
};
