import {Navigate, Route, Routes} from 'react-router-dom';
import AccountReady from 'screens/AccountReady/AccountReady';
import RunwayerCheckoutScreen from 'screens/CheckoutScreen/CheckoutScreen';
import RiseSurvey from 'screens/RiseSurvey/RiseSurvey';
import {WinbackCheckoutScreen} from 'screens/WinbackCheckout/screens/WinbackCheckout/WinbackCheckoutScreen';
import {SurveyContainer} from 'surveys/SurveyContainer';
import {survey_config_women_jan_2025} from 'surveys/women_jan_2025';

const CheckoutScreenContainer = () => {
  // const paywallExperimentEnabled = useFeatureValue('web_paywall_rise_vs_runwayer', false);
  // if (paywallExperimentEnabled) {
  //   return <RiseCheckoutScreenV1 variantName="rise-checkout-v1" />;
  // }
  return <RunwayerCheckoutScreen variantName="runwayer-checkout" />;
};

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/start" replace />} />
      <Route index path="start" element={<SurveyContainer />} />
      <Route path="w" element={<RiseSurvey surveyConfigJson={survey_config_women_jan_2025} />} />
      <Route path="checkout" element={<CheckoutScreenContainer />} />
      <Route path="welcome-back-discount-checkout" element={<WinbackCheckoutScreen />} />
      <Route path="account-ready" element={<AccountReady />} />
    </Routes>
  );
};
