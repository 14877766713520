import * as Sentry from '@sentry/react';
import {SupporedCountryCode} from './stripePlans';

export const getUserCountry = (): {countryCode: SupporedCountryCode; userLanguages: readonly string[]} => {
  // For QA Admin Tools
  const overrideCountryCode = localStorage.getItem('qa_countryCode');
  if (overrideCountryCode) {
    return {countryCode: overrideCountryCode as SupporedCountryCode, userLanguages: []};
  }

  let userLanguages: readonly string[] = [];
  try {
    userLanguages = window.navigator.languages;
  } catch {}

  try {
    let country: SupporedCountryCode = 'US';

    const preferredLanguageWithCountry = userLanguages.find(language => language.includes('-'));
    if (preferredLanguageWithCountry) {
      const userCountry = preferredLanguageWithCountry.split('-')[1];
      if (['US', 'GB', 'CA', 'AU'].includes(userCountry)) {
        country = userCountry as SupporedCountryCode;
      }
    }

    return {countryCode: country, userLanguages};
  } catch (e) {
    Sentry.captureException(e);
    return {countryCode: 'US', userLanguages};
  }
};

type PayPalCurrency = 'USD' | 'CAD' | 'GBP' | 'AUD';

export const getPayPalCurrency = (): PayPalCurrency => {
  const {countryCode} = getUserCountry();

  const countryCurrencyMapping: Record<SupporedCountryCode, PayPalCurrency> = {
    US: 'USD',
    CA: 'CAD',
    GB: 'GBP',
    AU: 'AUD',
  };

  return countryCurrencyMapping[countryCode] ?? 'USD';
};
