import React, {CSSProperties} from 'react';
import {ContinueButton} from './ContinueButton';

export type RiseSurveyInterstitialControlsButton = {
  visible: boolean;
};

type RiseSurveyInterstitialControlsProps = {
  onClick?: (() => void) | (() => Promise<void>);
  continueButtonTitle?: string;
  variant?: 'default' | 'inverted';
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
};

export const RiseSurveyInterstitialControls: React.FC<RiseSurveyInterstitialControlsProps> = ({
  continueButtonTitle,
  onClick,
  variant = 'default',
  style,
  disabled,
  loading,
}) => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingBottom: '16px',
        ...style,
      }}
    >
      <ContinueButton
        title={continueButtonTitle}
        onClick={onClick}
        variant={variant}
        disabled={disabled}
        loading={loading}
      />
    </div>
  );
};

export default RiseSurveyInterstitialControls;
