export const riseSurveyTheme = {
  root: 'sd-root-modern rise-root',
  body: 'sd-body rise-body',
  bodyNavigationButton: 'sd-btn rise-btn',
  container: 'sd-container-modern rise-container',
  navigation: {
    complete: 'sd-btn--action sd-navigation__complete-btn',
    prev: 'sd-navigation__prev-btn',
    next: '',
    start: 'sd-navigation__start-btn',
    preview: 'sd-navigation__preview-btn',
    edit: 'sd-btn sd-btn--small',
  },
  actionBar: {
    root: 'sd-action-bar rise-action-bar',
    item: 'sd-action rise-action',
    defaultSizeMode: '',
    smallSizeMode: '',
    itemPressed: 'sd-action--pressed',
    itemAsIcon: 'sd-action--icon',
    itemIcon: 'sd-action__icon',
    itemTitle: 'sd-action__title',
  },
  question: {
    title: ' rise-title',
    // title: 'sd-title sd-element__title sd-question__title rise-title',
    content: 'question-content',
    answered: 'question-answered',
    titleRequired: 'question-title-required',
  },
};
