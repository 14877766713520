import img_icon_lightbulb from 'images/icon-lightbulb.svg';

export const CallOutInfo: React.FC<{title: string}> = ({title}) => {
  return (
    <div
      className="left"
      style={{
        display: 'flex',
        justifySelf: 'flex-start',
        backgroundColor: '#E5F4F0',
        padding: 10,
        borderRadius: 17,
        color: '#005240',
        fontWeight: 800,
        fontSize: 16,
        marginBottom: 10,
      }}
    >
      <img src={img_icon_lightbulb} style={{maxHeight: '16px', marginRight: 8}} />
      {title}
    </div>
  );
};
