import React, {useEffect, useState} from 'react';
import {FunctionFactory, ITheme, Model, SurveyModel} from 'survey-core';
import 'survey-core/defaultV2.min.css';
import {Survey} from 'survey-react-ui';
import {RiseHeader} from './components/RiseHeader/RiseHeader';
import RiseSurveyControls from './components/RiseSurveyControls/RiseSurveyControls';
import {SegmentedProgressBar} from './components/SegmentedProgressBar/SegmentedProgressBar';
import {RiseSurveyConfig} from '../../types';
import Analytics from '../../utils/Analytics';
import {registerRiseInterstitial} from './components/RiseInterstitialTemplate/RiseInterstitialBase';
import {isInterstitial} from './utils/interstitial';
import './RiseSurvey.css';
import {riseSurveyTheme} from './RiseSurveyTheme';
import {preloadImages} from './utils/preloadImages';
import {SurveyData} from './utils/surveyData';
import {surveySwitchFn} from './utils/switchExpression';
import surveyTheme from './surveyTheme.json';
import {getCategoryName} from './utils/getCategoryName';
import {completeSurvey} from './utils/completeSurvey';
import {CategoryProgressBar} from './components/CategoryProgressBar/CategoryProgressBar';

/**
 * Setup SurveyJS instance
 */
export const surveyInstance = new Model();
surveyInstance.css = riseSurveyTheme;
surveyInstance.applyTheme(surveyTheme as ITheme);

registerRiseInterstitial();

// Register custom conditional functions
FunctionFactory.Instance.register('switch', surveySwitchFn);

type RiseSurveyProps = {
  surveyConfigJson: RiseSurveyConfig;
};

const anyRequiredQuestions = (page: any) => {
  return page.elements.some((element: any) => element.isRequired);
};

const shouldAutoAdvance = (page: any) => {
  return Boolean(page.jsonObj?.riseProperties?.autoAdvance);
};
/**
 * This is the heart of the Rise Survey system.
 * It sets up the survey, the header, and handles the controls.
 */

const getCategories = (sender: SurveyModel) => {
  return sender.visiblePages.map(p => getCategoryName(p));
};

const RiseSurvey: React.FC<RiseSurveyProps> = ({surveyConfigJson}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isInterstitialPage, setIsInterstitialPage] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [hideProgressBar, setHideProgressBar] = useState(false);
  const [hideCategory, setHideCategory] = useState(false);
  const [category, setCategory] = useState('');
  const [isRequiredQuestion, setIsRequiredQuestion] = useState(true);
  const [isAutoAdvance, setIsAutoAdvance] = useState(true);
  const [transitionClass, setTransitionClass] = useState('fade-in');
  const enableAnswerNewStyle = surveyConfigJson.enableAnswerNewStyle;
  const [categories, setCategories] = useState<string[]>(getCategories(surveyInstance));

  const hasCategories = categories?.some(Boolean);

  useEffect(() => {
    const imagesToPreload = surveyConfigJson.preloadImages ?? [];
    preloadImages(imagesToPreload);
  }, [surveyConfigJson.preloadImages]);

  useEffect(() => {
    Analytics.track('Survey Started', {
      survey_name: surveyConfigJson.title,
      surveyTitle: surveyConfigJson.title,
    });
  }, [surveyConfigJson]);

  useEffect(() => {
    const handleCurrentPageChanged = (sender: SurveyModel) => {
      const page = sender.currentPage;
      const isLastPage = sender.isLastPage;
      const isFirstPage = sender.isFirstPage;
      setIsLastPage(isLastPage);
      setIsFirstPage(isFirstPage);
      setCategories(getCategories(sender));
      setIsRequiredQuestion(anyRequiredQuestions(sender.currentPage));
      setIsAutoAdvance(shouldAutoAdvance(sender.currentPage));
      setIsInterstitialPage(isInterstitial(page));
      setHideProgressBar(page.jsonObj?.riseProperties?.hideProgressBar);
      setHideCategory(page.jsonObj?.riseProperties?.hideCategory);
      setCurrentQuestionIndex(sender.currentPageNo);
      setCategory(getCategoryName(page));
      setTransitionClass('fade-in');
    };

    const setInitialSurveyState = (sender: SurveyModel) => {
      const page = sender.currentPage;
      const isLastPage = sender.isLastPage;
      const isFirstPage = sender.isFirstPage;
      setCategories(getCategories(sender));
      setIsRequiredQuestion(anyRequiredQuestions(sender.currentPage));
      setIsAutoAdvance(shouldAutoAdvance(sender.currentPage));
      setIsFirstPage(isFirstPage);
      setIsLastPage(isLastPage);
      setIsInterstitialPage(isInterstitial(page));
      setHideProgressBar(page.jsonObj?.riseProperties?.hideProgressBar);
      setHideCategory(page.jsonObj?.riseProperties?.hideCategory);
      setCategory(getCategoryName(page));
    };

    const handleValueChanged = (sender: SurveyModel, question: any) => {
      const autoAdvance = shouldAutoAdvance(sender.currentPage);

      Analytics.track('Answer selected', {
        survey_name: surveyInstance.title,
        question_title: question.question.title,
        question_name: question.name,
        question_value: question.value,
        pageNumber: sender.currentPageNo,
      });
      SurveyData.save(sender);

      if (autoAdvance) {
        setTransitionClass('fade-out');
        setTimeout(() => {
          if (!sender.isLastPage) {
            sender.nextPage();
          } else {
            completeSurvey(sender);
          }
        }, 400);
      }
    };

    const handleComplete = () => {
      Analytics.track('Survey Completed', {
        data: surveyInstance.data,
      });
      setCurrentQuestionIndex(surveyInstance.pages.length);
    };

    surveyInstance.fromJSON(surveyConfigJson);
    SurveyData.restore(surveyInstance);
    setInitialSurveyState(surveyInstance); //set the initial Survey State
    const handleValueChanging = (sender: SurveyModel, question: any) => {
      // This handles clearing the options in a checkbox question when the user selects "prefer not to say"
      const isCheckboxQuestion = question.question.constructor.name === 'QuestionCheckboxModel';
      const {oldValue, value} = question;
      if (isCheckboxQuestion) {
        if (oldValue?.includes('prefer_not_to_say')) {
          question.value = question.value.filter((v: string) => v !== 'prefer_not_to_say');
        } else if (value?.includes('prefer_not_to_say')) {
          question.value = ['prefer_not_to_say'];
        }
      }
    };
    const handleAfterRenderQuestion = (survey: SurveyModel, options: any) => {
      // Change the enter key hint to done for text input questions
      if (options.question.getType() === 'text') {
        const inputElement = options.htmlElement.querySelector('input');
        if (inputElement) {
          inputElement.setAttribute('enterkeyhint', 'done');
        }
      }
      // For Name Question
      // Change the autocomplete and inputmode for the person_name question
      // Also add an event listener to the input element to hide the
      // keyboard when the user presses enter
      if (options.question.name === 'person_name') {
        const inputElement = options.htmlElement.querySelector('input');
        if (inputElement) {
          inputElement.setAttribute('autocomplete', 'given-name');
          inputElement.setAttribute('inputmode', 'text');
          inputElement.setAttribute('spellcheck', 'false');
          inputElement.addEventListener('keydown', (event: any) => {
            if (event.key === 'Enter') {
              event.preventDefault();

              // close keyboard but don't move to next page
              inputElement.blur(); // This will hide the keyboard
            }
          });
        }
      }
    };

    surveyInstance.onAfterRenderQuestion.add(handleAfterRenderQuestion);
    surveyInstance.onValueChanging.add(handleValueChanging);
    surveyInstance.onValueChanged.add(handleValueChanged);
    surveyInstance.onCurrentPageChanged.add(handleCurrentPageChanged);
    surveyInstance.onComplete.add(handleComplete);

    return () => {
      surveyInstance.onAfterRenderQuestion.remove(handleAfterRenderQuestion);
      surveyInstance.onValueChanging.remove(handleValueChanging);
      surveyInstance.onValueChanged.remove(handleValueChanged);
      surveyInstance.onCurrentPageChanged.remove(handleCurrentPageChanged);
      surveyInstance.onComplete.remove(handleComplete);
    };
  }, [surveyConfigJson]);

  return (
    <div className="container">
      <div>
        <RiseHeader
          backButton={!isFirstPage}
          onBackClick={() => {
            surveyInstance?.prevPage();
          }}
          enableCategoryHeader={hasCategories}
          hideCategory={hideCategory}
          category={category}
          firstPage={isFirstPage}
        />
        {hideProgressBar ? null : (
          <div style={{paddingTop: 8, paddingBottom: 24}}>
            {hasCategories ? (
              <CategoryProgressBar pageNumber={surveyInstance?.currentPageNo ?? 0} categories={categories} />
            ) : (
              <SegmentedProgressBar
                currentQuestionIndex={currentQuestionIndex}
                totalQuestions={surveyInstance?.pages.length ?? 0}
              />
            )}
          </div>
        )}
      </div>
      <div className={enableAnswerNewStyle ? `rise-radio-new-style survey-page-transition ${transitionClass}` : ''}>
        {surveyInstance ? <Survey model={surveyInstance} /> : null}
      </div>

      <RiseSurveyControls
        isLastPage={isLastPage}
        isInterstitial={isInterstitialPage}
        isAutoAdvance={isAutoAdvance}
        isRequired={isRequiredQuestion}
      />
    </div>
  );
};
export default RiseSurvey;
