import React from 'react';
import './Button.css';
import ButtonBase, {ButtonBaseProps} from './ButtonBase';

interface ButtonSolidProps extends ButtonBaseProps {
  variant?: 'default' | 'inverted';
  loading?: boolean;
}

export default function ButtonSolid({className, variant = 'default', ...rest}: ButtonSolidProps) {
  const variantClass = variant === 'inverted' ? 'rise-btn-solid--inverted' : '';
  return <ButtonBase className={`rise-btn-solid ${variantClass} ${className ?? ''}`} {...rest} />;
}
