import styles from './TrialTimeline.module.css';
import timelineBackground from './assets/background.svg';
import timeline from './assets/timeline.svg';

interface Props {
  reminderDate: string;
  trailEndDate: string;
}
export default function TrialTimeline({reminderDate, trailEndDate}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.header} style={{backgroundImage: `url(${timelineBackground})`}}>
        See how RISE can transform your sleep and energy in a week
      </div>
      <div className={styles.timeline}>
        <img className={styles.timelineImage} src={timeline} alt="timeline" />
        <div className={styles.timelineEntry} style={{top: '0px'}}>
          <div className={styles.timelineEntryTitle}>Today</div>
          <div className={styles.timelineEntryDescription}>
            Start your <strong>1 week</strong> trial.
          </div>
        </div>
        <div className={styles.timelineEntry} style={{top: '61.4961px'}}>
          <div className={styles.timelineEntryTitle}>{reminderDate}</div>
          <div className={styles.timelineEntryDescription}>
            4 of 5 RISE users <span style={{color: 'var(--color-eggplant)'}}>feel better within 5 days</span>. We’ll
            remind you before your trial ends so you can decide.
          </div>
        </div>
        <div className={styles.timelineEntry} style={{top: '161px'}}>
          <div className={styles.timelineEntryTitle}>{trailEndDate}</div>
          <div className={styles.timelineEntryDescription}>Your trial ends and you’ll be billed.</div>
        </div>
      </div>
    </div>
  );
}
