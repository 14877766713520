import styles from './Goals.module.css';
import icon_bed from './assets/bed.svg';
import icon_sun from './assets/sun.svg';
import icon_star from './assets/star.svg';
import icon_zzz from './assets/zzz.svg';

export function Goals() {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Designed to help meet your goals</div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={icon_star} alt="star" />
          </div>
          <div className={styles.text}>Wake feeling refreshed</div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={icon_zzz} alt="zzz" />
          </div>
          <div className={styles.text}>Reduce sleep debt</div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={icon_bed} alt="bed" />
          </div>
          <div className={styles.text}>Improve sleep quality</div>
        </div>

        <div className={styles.item}>
          <div className={styles.icon}>
            <img src={icon_sun} alt="sun" />
          </div>
          <div className={styles.text}>Align your circadian rhythm</div>
        </div>
      </div>
    </div>
  );
}
