import {addDays, format} from 'date-fns';
import {createAccount} from 'externalAPIs/hypnos';
import {capitalize} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Goals} from 'screens/WinbackCheckout/components/Goals/Goals';
import {MoneyBackGuarantee} from 'screens/WinbackCheckout/components/MoneyBackGuarantee/MoneyBackGuarantee';
import {PaymentElement} from 'screens/WinbackCheckout/components/PaymentElement/PaymentElement';
import {PlanSelection} from 'screens/WinbackCheckout/components/PlanSelection/PlanSelection';
import PriceToday from 'screens/WinbackCheckout/components/PriceToday/PriceToday';
import TrialTimeline from 'screens/WinbackCheckout/components/TrialTimeline/TrialTimeline';
import {useSessionStorage} from 'usehooks-ts';
import Analytics from 'utils/Analytics';
import {getEmailFromUrlParam} from 'utils/getEmailFromUrlParam';
import {getUserCountry} from 'utils/locale';
import {config7dQuarterly30, PlanInfo} from 'utils/stripePlans';
import {useCheckoutScreenAnalytics} from 'utils/useCheckoutScreenAnalytics';
import {useEffectOnce} from 'utils/useEffectOnce';
import {AccountCreation} from '../AccountCreationScreen/AccountCreationScreen';
import styles from './WinbackCheckoutScreen.module.css';

export function WinbackCheckoutScreen() {
  const [showAccountCreation, setShowAccountCreation] = useState(false);
  const {countryCode: country, userLanguages} = getUserCountry();
  const navigate = useNavigate();
  const [, setSubscription] = useSessionStorage('subscription', '');
  const [planToPurchase, setPlanToPurchase] = useState<PlanInfo>(config7dQuarterly30[country]);
  const todayDate = new Date();
  const reminderDate = format(addDays(todayDate, 5), 'MMM dd');
  const trailEndDate = format(addDays(todayDate, 7), 'MMM dd');
  const {countryCode: locale} = getUserCountry();
  const location = useLocation();
  const name = capitalize(new URLSearchParams(location.search).get('name') ?? '');
  const email = getEmailFromUrlParam(location.search);

  const winbackQuarterlyPlan = config7dQuarterly30[locale];

  const paymentRef = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    Analytics.track('Country Code Determined', {country, userLanguages});
  });

  useCheckoutScreenAnalytics('winback_checkout');

  useEffect(() => {
    Analytics.track('Plan Selected', {
      ...planToPurchase,
    });
  }, [planToPurchase]);

  const onGetMyPlanClick = () => {
    Analytics.track('Get My Plan Clicked');
    const bannerHeight = 73 + 20;
    window.scrollTo({top: (paymentRef.current?.offsetTop ?? 0) - bannerHeight, behavior: 'smooth'});
  };

  const handleCheckoutSuccess = async (subscription: string) => {
    setSubscription(subscription);
    Analytics.track('Checkout Success', {email: email ?? 'none'});

    if (email) {
      const {person_id} = await createAccount(email, subscription);
      if (person_id) {
        Analytics.identify(person_id, {email});
        navigate(`/account-ready?email=${encodeURIComponent(email)}`);
      } else {
        Analytics.track('Winback error creating account');
        setShowAccountCreation(true);
      }
    } else {
      Analytics.track('Winback email missing during checkout');
      setShowAccountCreation(true);
    }
  };

  if (showAccountCreation) {
    return <AccountCreation />;
  }

  return (
    <div className={styles.winbackCheckoutContainer} data-testid="screen-checkout-rise">
      <div>
        <div className={styles.headline}>{name ? `${name}'s` : 'Your'} Personalized Plan</div>
        <Goals />
      </div>
      <div>
        <PriceToday
          title="7 day trial"
          price={winbackQuarterlyPlan.introPrice.toString()}
          currencySymbol={winbackQuarterlyPlan.label}
        />
      </div>
      <div>
        <TrialTimeline reminderDate={reminderDate} trailEndDate={trailEndDate} />
      </div>
      <div>
        <PlanSelection
          selectedPlan={planToPurchase}
          onPriceSelection={plan => setPlanToPurchase(plan)}
          onButtonClick={onGetMyPlanClick}
          trailEndDate={trailEndDate}
        />
      </div>

      <div>
        <MoneyBackGuarantee />
      </div>

      <div ref={paymentRef} style={{marginBottom: '10rem'}}>
        <PaymentElement onSuccess={handleCheckoutSuccess} planToPurchase={planToPurchase} />
      </div>
    </div>
  );
}
