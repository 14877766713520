import {createAccountPreSubscription} from 'externalAPIs/hypnos';
import React, {useState} from 'react';
import 'screens/RiseSurvey/components/RiseInterstitialTemplate/interstitialBase.css';
import {RiseInterstitialProps} from 'screens/RiseSurvey/components/RiseInterstitialTemplate/RiseInterstitialBase';
import RiseSurveyInterstitialControls from 'screens/RiseSurvey/components/RiseSurveyControls/RiseSurveyInterstitialControls';
import {surveyInstance} from 'screens/RiseSurvey/RiseSurvey';
import Analytics from 'utils/Analytics';
import validateEmail from 'utils/validateEmail';
import styles from './AccountCreationInterstitial.module.css';

const AccountCreationInterstitial: React.FC<RiseInterstitialProps> = () => {
  const [error, setError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const signUp = async (email: string) => {
    setError('');
    setLoading(true);
    if (!email) {
      throw new Error('Please enter your email');
    }
    if (!validateEmail(email)) {
      throw new Error('Please enter a valid email');
    }

    const result = await createAccountPreSubscription(email);
    // Add email to survey data
    surveyInstance.data = {
      ...surveyInstance.data,
      email,
    };

    if (result.error) {
      setError(result.errorMessage);
    } else {
      Analytics.identify({email});
    }
    setLoading(false);
  };

  const goal = surveyInstance.getVariable('profile_goal_phrase');

  return (
    <div className="interstitial ">
      <div className={styles.title}>
        Enter your email to get your personalized plan to {goal?.toLowerCase() ?? 'improve your sleep'}
      </div>

      <div className={styles.emailInputContainer}>
        <input
          id="email"
          type="email"
          className={`${styles.emailInput} ${error ? styles.emailInputError : ''}`}
          placeholder="Enter email"
          name="email"
          autoComplete="email"
          value={email}
          onBlur={() => {
            if (!validateEmail(email.trim())) {
              setError('Please enter a valid email');
            }
          }}
          onChange={e => {
            setError('');
            setEmail(e.target.value);
          }}
        />
        {error ? <div className={styles.errorMessage}>{error}</div> : null}
      </div>

      <div className={styles.privacyPolicy}>
        Your privacy matters. By continuing, you agree to our{' '}
        <a href="https://www.risescience.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        &{' '}
        <a href="https://www.risescience.com/terms" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        . RISE may send occasional email - you can unsubscribe at any time.
      </div>

      <RiseSurveyInterstitialControls
        continueButtonTitle={'Next'}
        loading={loading}
        disabled={Boolean(!validateEmail(email) || error)}
        onClick={async () => {
          setError('');
          setLoading(true);

          await signUp(email);
          setLoading(false);
        }}
      />
    </div>
  );
};

export default AccountCreationInterstitial;
