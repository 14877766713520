import ReactPixel from 'react-facebook-pixel';
import Analytics from './Analytics';
import {useEffectOnce} from './useEffectOnce';

export const useCheckoutScreenAnalytics = (variantName: string) => {
  useEffectOnce(() => {
    Analytics.track('InitiateCheckout', {checkoutVariant: variantName});
    ReactPixel.track('InitiateCheckout');

    try {
      dataLayer.push({event: 'add_to_cart'});
    } catch (e) {
      // Silently handle missing dataLayer
    }
  });
};
