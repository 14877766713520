import RiseSurvey from 'screens/RiseSurvey/RiseSurvey';
import config_control from 'surveys/control_config';
import {useFeature} from '@growthbook/growthbook-react';
import config_variant from './survey_email_before_checkout_jan_21_2025';
export const controlSurveyConfig = config_control;
export const experimentSurveyConfig = config_variant;

export const SurveyContainer = () => {
  const inExperiment = useFeature('web_email_before_checkout');
  const randomSurveyConfig = inExperiment.value ? experimentSurveyConfig : controlSurveyConfig;
  return <RiseSurvey surveyConfigJson={randomSurveyConfig} />;
};
