/** Gets the literal email from the URL param and replaces spaces with plus signs
 * (pluses (+) decoded as spaces)
 *
 * @returns Email address or empty string if no email is found
 */
export const getEmailFromUrlParam = (search: string) => {
  const emailString = new URLSearchParams(search).get('email') ?? '';
  const email = decodeURIComponent(emailString);
  return email.replaceAll(' ', '+');
};
